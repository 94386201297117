import React, { useState, useEffect } from "react";
import background from "./assets/background.png";
import backgroundMobile from "./assets/background-mobile.png";
import twitterIcon from "./assets/twitter-icon.svg";
// import youtubeIcon from "./assets/youtube-icon.svg";
import dextoolsIcon from "./assets/dextools-icon.svg";
import telegramIcon from "./assets/telegram-icon.svg";
import logo from "./assets/logo.svg";
import blueCircle from "./assets/blue-circle.svg";
import copyIcon from "./assets/copy-icon.svg";

export default function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);
  const [copy, setCopy] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText("0xe842e272a18625319cc36f64eb9f97e5ad0c32af");
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 3000);
  };

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 640);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="min-h-screen flex flex-col bg-cover bg-center bg-no-repeat"
      style={{
        fontFamily: "Helvetica Neue",
        backgroundImage: `url(${isMobile ? backgroundMobile : background})`,
      }}
    >
      <div className="flex flex-row items-center justify-between px-4 py-3 sm:px-6 sm:py-5">
        <div className="flex flex-row items-center justify-between gap-2">
          <img src={logo} alt="logo" className="w-[45px] h-[45px]" />
          <p className="text-xl sm:text-2xl font-bold text-black">$RYU</p>
        </div>

        <div className="flex flex-row items-center justify-between gap-2">
          <p className="text-base font-bold text-black-50 ml-2">
            POWERED BY BASE
          </p>
          <img src={blueCircle} alt="blueCircle" />
        </div>

        <div className="hidden sm:flex flex-row items-center gap-4">
          <a
            className="w-[42px] h-[42px] flex items-center justify-center p-2.5 rounded-full border border-gray bg-white"
            href="http://twitter.com/ryumemecoin"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={twitterIcon} alt="twitterIcon" />
          </a>
          {/* <div className="w-[42px] h-[42px] flex items-center justify-center p-2.5 rounded-full border border-gray bg-white">
            <img src={youtubeIcon} alt="youtubeIcon" />
          </div> */}
          <a
            className="w-[42px] h-[42px] flex items-center justify-center p-2.5 rounded-full border border-gray bg-white"
            href="https://www.dextools.io/app/en/base/pair-explorer/0x1102b8cfebad20d131badff09bfe8a6230b61c45?t=1712849321252"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={dextoolsIcon} alt="dextoolsIcon" />
          </a>
          <a
            className="w-[42px] h-[42px] flex items-center justify-center p-2.5 rounded-full border border-gray bg-white"
            href="https://t.me/Ryuentry"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={telegramIcon} alt="telegramIcon" />
          </a>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center gap-2 sm:gap-10 mt-24 sm:mt-16 text-center">
        <p className="text-3xl sm:text-[58px] font-bold text-black">
          “THE DRAGON IS BLUE”
        </p>
        <p className="text-[18px] sm:text-[32px] font-semibold text-black">
          Spitting Fire in a World of Fur & Frogs
        </p>
        <a
          className="flex flex-row items-center justify-center gap-1 px-5 sm:px-8 py-1.5 sm:py-4 rounded-xl sm:rounded-[32px] border sm:border-2 border-black bg-white hover:bg-gray-light"
          href="https://app.uniswap.org/swap?outputCurrency=0xe842E272a18625319CC36F64eB9f97E5ad0c32AF&chain=base"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span className="text-base sm:text-xl font-bold text-black">
            Buy Now
          </span>
          <span className="text-xs">(Change network to Base</span>
          <img
            src={blueCircle}
            alt="blueCircle"
            className="w-4 h-4"
          />
          <span className="text-xs">)</span>
        </a>
      </div>

      <div className="flex flex-row items-center justify-center gap-4 mx-auto mt-4 px-4 sm:px-8 py-4 rounded-[32px] border-2 border-black bg-pink">
        <div className="px-4 rounded-[32px] bg-white">
          <p className="text-xl font-bold text-blue">CA:</p>
        </div>
        <p className="hidden sm:flex text-xl font-bold text-black">
          0xe842e272a18625319cc36f64eb9f97e5ad0c32af
        </p>
        <span className="flex sm:hidden flex-col">
          <p className="text-xs font-bold text-black">
            0xe842e272a18625319cc36
          </p>
          <p className="text-xs font-bold text-black">f64eb9f97e5ad0c32af</p>
        </span>
        <button
          className="w-fit p-1.5 rounded-full border border-white bg-blue"
          onClick={handleCopy}
        >
          {copy ? (
            <p className="text-sm font-bold text-white">Copied!</p>
          ) : (
            <img src={copyIcon} alt="copy" />
          )}
        </button>
      </div>

      <div className="flex sm:hidden flex-row items-center justify-center gap-4 absolute bottom-5 inset-x-0">
        <a
          className="w-[42px] h-[42px] flex items-center justify-center p-2.5 rounded-full border border-gray bg-white"
          href="http://twitter.com/ryumemecoin"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={twitterIcon} alt="twitterIcon" />
        </a>
        {/* <div className="w-[42px] h-[42px] flex items-center justify-center p-2.5 rounded-full border border-gray bg-white">
          <img src={youtubeIcon} alt="youtubeIcon" />
        </div> */}
        <a
          className="w-[42px] h-[42px] flex items-center justify-center p-2.5 rounded-full border border-gray bg-white"
          href="https://www.dextools.io/app/en/base/pair-explorer/0x1102b8cfebad20d131badff09bfe8a6230b61c45?t=1712849321252"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={dextoolsIcon} alt="dextoolsIcon" />
        </a>
        <a
          className="w-[42px] h-[42px] flex items-center justify-center p-2.5 rounded-full border border-gray bg-white"
          href="https://t.me/Ryuentry"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={telegramIcon} alt="telegramIcon" />
        </a>
      </div>
    </div>
  );
}
